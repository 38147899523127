@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');

$primary: #3597d3; /* MAIN COLOR */
$secondary: #2c3e50; /* SECONDARY COLOR */
$gray: #666; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$footerLinks: #fff;

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #0065dd;
  -webkit-text-fill-color: #0065dd;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}


html,body {
  height: 100%;

  /*margin: 0;
  padding: 0;
  overflow: auto;*/
}

body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 1.2em;
  h1,h2,h3,h4,h5,h6 {
    font-family: 'Droid Serif', serif;
  }
}

.flash {
	display:none;
}

q,blockquote {
    quotes: "“" "”";
}

.navbarFixed {
 position: fixed;
 width: 100%;
 top: 0;
 z-index: 999;
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #0065dd;
  -webkit-text-fill-color: #0065dd;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}


nav.navbar {
	z-index: 1000;
	border: none;

	.navbar-nav {

	    > li > a {
			text-align: center;
			margin-top: 22px;
			display: flex;
			align-items: center;
			color: $blk;

			@media (max-width: 767px) {
				  margin-top: 0;
				  // padding: 6px;
			    display: inline-block;
			}

		    &:hover {
		    	background: $primary;
		    	color:$wht;
		    	@media (max-width: 1024px) {
			    	background: transparent;
	    			color: $blk;
				  	outline: 0;
		    	}
		    }

			&:focus, &:active {
			  background: transparent;
			  color: $blk;
			  outline: 0;
			}
		}
	}
}

.navbar-right {
	margin-top: 0px;
}
.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/
@mixin btn {
	border: none;
	background: $primary;
	color: $wht;
	padding: 0.8em 2em;
	font-size: 1em;
	font-weight: 400;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;
	display: inline-block;
	margin: 1em 0em;
	transition: 0.7s ease all;
  text-decoration: none;

	&:hover {
		background: $secondary;
    color: $wht;
		text-decoration: none;

			@media (max-width: 1024px) {
        background: $primary;
        color: $wht;
			}
	}
}

.btn-default {
	@include btn;
}

// mixins end here


// sections start
section{
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 120px 0;
  @media(max-width:991px){
      padding:80px 0;
  }
  @media(max-width: 767px){
      padding:60px 0;
  }
  @media(max-width: 600px){
      padding:40px 0;
  }
  li {
    list-style: none;
  }
}
.hero-slider {
  // margin: 50px auto;;
}
.section-a {
  color: $blk;
  background-color: $wht;
}
.section-b {
  background-color: $secondary;
  color: $wht;
  h1{
    line-height: 3em;
  }
  p {
    margin-bottom: 60px;
  }
}
.section-c {
  color: $blk;
  background-color: $wht;
  font-size: 1.2em;

  ul {
    padding: 0;
  }
  li {
    list-style: none;
    color: $blk;
    a{
      color: $blk;
      line-height: 2em;
    }
  }
}
// sections end


.modal-dialog {
	max-width: 500px;
	width: 100%;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	a.btn, a.btn-default, button {
		@include btn
		margin: 0;
		display: block;
	}
}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}


footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;
	font-size: 15px;

	a {
		color: $footerLinks;
		white-space: nowrap;
		font-size: 15px;

		&:hover {
			color: lighten($footerLinks, 10%);
			outline: 0 !important;
 			text-decoration: none;
		}

		&:focus {
			color: $footerLinks;
			outline: 0 !important;
 			text-decoration: none;
		}
	}

	p {
		font-size: 15px;

		@media (max-width: 990px) {
			font-size: 13px;
		}
	}
}




select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}


a,a:hover,a:focus,a:active {
  outline: 0 !important;
}


.logo {
	max-height:100px;
	padding: 0.5em;
}


@media(max-width: 767px) {
	.logo {
		max-height:70px;
	}

    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {


  .navbar-toggle {
    margin-top: 5px;
  }

}


.top-pad {
	padding: 3em;
	background: white;
  font-size: 1.2em;
}


.flex {
	display: flex;
	justify-content: center;
	align-items: center;

	@media (max-width: 1024px) {
		display: block;  /* may need to be inline-block */
	}
}
